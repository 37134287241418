import { useEffect } from 'react';

import { hashToFragment, scrollTo } from './elementScroll';

export const useScrollToFragment = (fragmentId: string | null): void => {
  useEffect(() => {
    const { hash } = window.location;
    const activeFragmentId = hashToFragment(hash);

    if (activeFragmentId === fragmentId) {
      scrollTo(fragmentId);
    }
  }, [fragmentId]);
};
