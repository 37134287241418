import { FC } from 'react';
import { Element } from 'react-scroll';

import { useScrollToFragment } from '@lib/scroll/useScrollToFragment';

import { DefaultScrollToLineVariation } from '../types';

interface Props {
  slice: DefaultScrollToLineVariation;
}

export const DefaultScrollToLine: FC<Props> = (props) => {
  const { slice } = props;
  const fragmentId = slice.primary.fragmentId;

  useScrollToFragment(fragmentId);

  if (!fragmentId) {
    return null;
  }

  return <Element name={fragmentId} />;
};
