import { FC } from 'react';

import { DefaultScrollToLine } from './default-slice/DefaultScrollToLine';
import { DefaultScrollToLineVariation, ScrollToLineVariations } from './types';

export interface ScrollToLineSliceProps {
  slice: ScrollToLineVariations;
}

export const ScrollToLineSlice: FC<ScrollToLineSliceProps> = (props) => {
  const { slice } = props;

  return <DefaultScrollToLine slice={slice as DefaultScrollToLineVariation} />;
};
